import axios from 'axios';
import { apiUrl } from '@/env';
import {
  IUserProfile,
  IUserProfileUpdate,
  IUserProfileCreate,
  IServiceCreate,
  IService,
  IServiceUpdate,
  IMessageCreate,
  ISenderBans,
  ISenderBanCreate,
} from './interfaces';
import { ISetting } from '@/interfaces/setting';

function authHeaders(token: string) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

export const api = {
  async logInGetToken(username: string, password: string) {
    const params = new URLSearchParams();
    params.append('username', username);
    params.append('password', password);

    return axios.post(`${apiUrl}/api/v1/login/access-token`, params);
  },
  async getMe(token: string) {
    return axios.get<IUserProfile>(`${apiUrl}/api/v1/users/me`, authHeaders(token));
  },
  async updateMe(token: string, data: IUserProfileUpdate) {
    return axios.patch<IUserProfile>(`${apiUrl}/api/v1/users/me`, data, authHeaders(token));
  },
  async getUsers(token: string) {
    return axios.get<IUserProfile[]>(`${apiUrl}/api/v1/users/`, authHeaders(token));
  },
  async getUser(token: string, id: number) {
    return axios.get<IUserProfile>(`${apiUrl}/api/v1/users/${id}`, authHeaders(token));
  },
  async updateUser(token: string, userId: number, data: IUserProfileUpdate) {
    return axios.patch(`${apiUrl}/api/v1/users/${userId}`, data, authHeaders(token));
  },
  async createUser(token: string, data: IUserProfileCreate) {
    return axios.post(`${apiUrl}/api/v1/users/`, data, authHeaders(token));
  },
  async deleteUser(token: string, userId: number) {
    return axios.delete(`${apiUrl}/api/v1/users/${userId}`, authHeaders(token));
  },
  async deleteUsers(token: string, usersIds: number[]) {
    return axios.delete(`${apiUrl}/api/v1/users/delete/`, { data: usersIds, ...authHeaders(token) });
  },

  async getMessagesStatistics(token: string, fromDate: string, toDate: string) {
    return axios.get(`${apiUrl}/api/v1/messages/statistics/`, {
      params: { from_date: fromDate, to_date: toDate },
      ...authHeaders(token),
    });
  },
  async getMessagesByDay(token: string, fromDate: string, toDate: string) {
    return axios.get(`${apiUrl}/api/v1/messages/by_day/`, {
      params: { from_date: fromDate, to_date: toDate },
      ...authHeaders(token),
    });
  },
  async createMessage(token: string, data: IMessageCreate) {
    return axios.post(`${apiUrl}/api/v1/messages/`, data, authHeaders(token));
  },
  async uploadReceiversFile(token: string, file: File) {
    const formData = new FormData();
    formData.append('file', file);
    return axios.post(`${apiUrl}/api/v1/messages/upload_receivers_file/`, formData, {
      headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}` },
    });
  },
  async getMessages(
    token: string,
    fromDate: string | null,
    toDate: string | null,
    receiver: string | null,
    sender: string | null,
    messageId: string | null,
    skip: number,
    limit: number,
    orderBy: string,
    descending: boolean,
  ) {
    return axios.get(`${apiUrl}/api/v1/messages/`, {
      params: {
        from_date: fromDate,
        to_date: toDate,
        receiver,
        sender,
        message_id: messageId,
        skip,
        limit,
        order_by: orderBy,
        descending,
      },
      ...authHeaders(token),
    });
  },

  async createService(token: string, data: IServiceCreate) {
    return axios.post(`${apiUrl}/api/v1/services/`, data, authHeaders(token));
  },
  async getService(token: string, id: number) {
    return axios.get<IService>(`${apiUrl}/api/v1/services/${id}`, authHeaders(token));
  },
  async getServices(token: string) {
    return axios.get<IService[]>(`${apiUrl}/api/v1/services/`, authHeaders(token));
  },
  async updateService(token: string, serviceId: number, data: IServiceUpdate) {
    return axios.patch(`${apiUrl}/api/v1/services/${serviceId}`, data, authHeaders(token));
  },
  async deleteService(token: string, serviceId: number) {
    return axios.delete(`${apiUrl}/api/v1/services/${serviceId}`, authHeaders(token));
  },
  async deleteServices(token: string, serviceIds: number[]) {
    return axios.delete(`${apiUrl}/api/v1/services/delete/`, { data: serviceIds, ...authHeaders(token) });
  },

  async getSenderBans(token: string, skip: number, limit: number, sender: string) {
    return axios.get<ISenderBans>(`${apiUrl}/api/v1/mo_sender_bans/`, {
      params: { skip, limit, sender },
      ...authHeaders(token),
    });
  },
  async deleteSenderBan(token: string, sender: string) {
    return axios.delete(`${apiUrl}/api/v1/mo_sender_bans/${sender}`, authHeaders(token));
  },
  async deleteSenderBans(token: string, senders: string[]) {
    return axios.delete(`${apiUrl}/api/v1/mo_sender_bans/delete/`, { data: senders, ...authHeaders(token) });
  },
  async createSenderBan(token: string, data: ISenderBanCreate) {
    return axios.post(`${apiUrl}/api/v1/mo_sender_bans/`, data, authHeaders(token));
  },

  async getSettings(token: string) {
    return axios.get<ISetting[]>(`${apiUrl}/api/v1/settings/`, authHeaders(token));
  },
  async createSettings(token: string, data: ISetting[]) {
    return axios.post(`${apiUrl}/api/v1/settings/`, data, authHeaders(token));
  },
};
