import { api } from '@/api';
import { saveLocalToken, getLocalToken, removeLocalToken } from '@/utils';
import router from '@/router';
import { ActionContext } from 'vuex';
import {
    commitSetToken,
    commitSetLoggedIn,
    commitSetLogInError,
    dispatchGetUserProfile,
    dispatchRouteLoggedIn,
    dispatchLogOut,
    commitSetUserProfile,
    dispatchCheckApiError,
    dispatchRemoveLogIn,
    dispatchRouteLogOut,
    commitRemoveNotification,
    commitAddNotification,
    commitSetNotifications,
} from './accessors';
import { AxiosError } from 'axios';
import { State } from '../state';
import { MainState, AppNotification } from './state';
import { IMessageCreate } from '@/interfaces';

type MainContext = ActionContext<MainState, State>;

export const actions = {
    async actionLogIn(context: MainContext, payload: { username: string; password: string }) {
        try {
            const response = await api.logInGetToken(payload.username, payload.password);
            const token = response.data.access_token;
            if (token) {
                saveLocalToken(token);
                commitSetToken(context, token);
                commitSetLoggedIn(context, true);
                commitSetLogInError(context, false);
                await dispatchGetUserProfile(context);
                await dispatchRouteLoggedIn(context);
                commitAddNotification(context, { content: 'Logged in', color: 'success' });
            } else {
                await dispatchLogOut(context);
            }
        } catch (err) {
            commitSetLogInError(context, true);
            await dispatchLogOut(context);
        }
    },
    async actionGetUserProfile(context: MainContext) {
        try {
            const response = await api.getMe(context.state.token);
            if (response.data) {
                commitSetUserProfile(context, response.data);
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionCreateMessage(context: MainContext, payload: IMessageCreate) {
        try {
            const loadingNotification = { content: 'sending message', showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = await api.createMessage(context.state.token, payload);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Message successfully sent', color: 'success' });
            return response.data;
        } catch (error) {
            await dispatchCheckApiError(context, error);
            return false;
        }
    },
    async actionUploadReceiversFile(context: MainContext, file: File) {
        try {
            const loadingNotification = { content: 'uploading receivers file', showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = await api.uploadReceiversFile(context.state.token, file);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'File uploaded', color: 'success' });
            return response.data;
        } catch (error) {
            await dispatchCheckApiError(context, error);
            return false;
        }
    },
    async actionUpdateUserProfile(context: MainContext, payload) {
        try {
            const loadingNotification = { content: 'saving', showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.updateMe(context.state.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetUserProfile(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Profile successfully updated', color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionCheckLoggedIn(context: MainContext) {
        if (!context.state.isLoggedIn) {
            let token = context.state.token;
            if (!token) {
                const localToken = getLocalToken();
                if (localToken) {
                    commitSetToken(context, localToken);
                    token = localToken;
                }
            }
            if (token) {
                try {
                    const response = await api.getMe(token);
                    commitSetLoggedIn(context, true);
                    commitSetUserProfile(context, response.data);
                } catch (error) {
                    await dispatchRemoveLogIn(context);
                }
            } else {
                await dispatchRemoveLogIn(context);
            }
        }
    },
    async actionRemoveLogIn(context: MainContext) {
        removeLocalToken();
        commitSetToken(context, '');
        commitSetLoggedIn(context, false);
    },
    async actionLogOut(context: MainContext) {
        await dispatchRemoveLogIn(context);
        await dispatchRouteLogOut(context);
    },
    async actionUserLogOut(context: MainContext) {
        await dispatchLogOut(context);
        commitAddNotification(context, { content: 'Logged out', color: 'success' });
    },
    actionRouteLogOut(context: MainContext) {
        if (router.currentRoute.path !== '/login') {
            router.push('/login');
        }
    },
    async actionCheckApiError(context: MainContext, payload: AxiosError) {
        if (payload.response!.status === 401) {
            await dispatchLogOut(context);
        } else if (payload.response!.data.hasOwnProperty('detail')) {
            commitSetNotifications(context, [{ content: payload.response!.data.detail, color: 'error'}] );
        } else {
            commitSetNotifications(
                context,
                [{ content: 'An error occurred while processing your request', color: 'error'}],
            );
        }
    },
    actionRouteLoggedIn(context: MainContext) {
        if (router.currentRoute.path === '/login' || router.currentRoute.path === '/') {
            router.push('/main');
        }
    },
    async removeNotification(context: MainContext, payload: { notification: AppNotification, timeout: number }) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                commitRemoveNotification(context, payload.notification);
                resolve(true);
            }, payload.timeout);
        });
    },
};
